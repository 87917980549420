<template>
    <div class="rawMateriaPrizeChildBox">
        <div class="rawMateriaPrizeChild">
            <h1 class="engineering-head">
                <span></span>
                <span>{{ childData.gysmc }}{{ childData.flmc }}价格调整</span>
                <span class="iconfont" @click="closeChildAlert">&#xe67d;</span>
            </h1>
            <div class="engineering-content">
                <div class="child-one">
                    <div class="table-title">
                        {{ childData.ggmc }}
                    </div>
                    <el-table
                        :data="tableDataTwo"
                        border
                    >
                        <el-table-column
                            prop="item"
                            label="调价时间"
                        >
                        </el-table-column>
                        <el-table-column
                            v-for="(item, index) in tableName"
                            :key="index"
                            :label="item"
                        >
                            <template slot-scope="scope">
                                <div v-for="(items, idx) in tableList" :key="idx">
                                    <span v-if="items.name === item && items.date === scope.row.item">{{ items.jg }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="child-two">
                    <h1>{{ childData.gysmc }}{{ childData.flmc }}价格调整</h1>
                    <div style="width:100%;height:450px" id="usedQuantityId"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import addBgImages from '../../../utils';
// 按施工部位
export default {
    data() {
        return {
            tableList: [],
            echartsName: [],
            echartsJg: [],
            tableData: [],
            tableDataTwo: [],
            tableName: [],
            echartsColor: ['#EA8343', '#3E79C4', '#E6E429', '#FA9E94'],
            tableDataRes: {},
        };
    },
    props: {
        childData: {
            type: Object,
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.salesanalysisSgbw();
        });
    },
    methods: {
        closeChildAlert() {
            this.$emit('closeChildAlert');
        },
        usedQuantityFun(data) {
            const myChart = this.$echarts.init(document.getElementById('usedQuantityId'));
            const option = {
                grid: {
                    top: '15%',
                    left: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    data: this.tableName,
                },
                xAxis: {
                    type: 'category',
                    data: this.tableData,
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: { show: false },
                },
                yAxis: {
                    nameTextStyle: {
                        padding: [0, 0, -20, 45], // 四个数字分别为上右下左与原位置距离
                        color: '#979EA7',
                        fontSize: '10',
                    },
                    type: 'value',
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                            type: 'dashed',
                        },
                    },
                },
                series: data,

            };
            myChart.setOption(option, true);
        },
        // 按施工部位
        salesanalysisSgbw() {
            const data = this.childData;
            this.$axios
                .get(`/interfaceApi/report/materialanalysis/ycljgtz/flygys/${data.gysid}/${data.flbm}/${data.zlbm}/${data.ggbm}/year`)
                .then(res => {
                    this.tableDataRes = res;
                    this.tableList = this.tableDataRes.list;
                    this.tableData = this.tableDataRes.date;
                    this.tableName = this.tableDataRes.name;
                    this.tableList.forEach(item => {
                        this.echartsName.push(item.name);
                        this.echartsJg.push(item.jg);
                    });
                    // 表格调价时间
                    this.tableDataTwo = this.tableData.map(item => {
                        return { item };
                    });
                    // 分组名称
                    const getSeriesData = this.tableDataRes.name.map(item => {
                        const arr = this.tableDataRes.list.filter(key => {
                            return key.name === item;
                        });
                        return arr;
                    });
                    // 价格调整
                    let htEchartSeriesData = [];
                    htEchartSeriesData = getSeriesData.map((item, index) => {
                        let htItemListData = [];
                        htItemListData = item.map(info => {
                            return info.jg;
                        });
                        const arrName = this.tableName[index];
                        const arrColor = this.echartsColor[index];
                        const htEchartSeries = {
                            name: arrName,
                            type: 'line',
                            data: htItemListData,
                            label: {
                                fontFamily: 'QuartzLTStd',
                                show: true,
                                position: 'top',
                                color: arrColor,
                                fontSize: '14',
                                padding: 5,
                                // backgroundColor: {
                                //     image: addBgImages(index, 'top'),
                                // },
                            },
                            itemStyle: {
                                color: arrColor,
                            },
                        };
                        return htEchartSeries;
                    });
                    this.usedQuantityFun(htEchartSeriesData);
                })
                .catch(err => {
                    console.log(err);
                });
        },
    },
};

</script>
<style lang='stylus'>
.rawMateriaPrizeChildBox
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,0.4)
    z-index 99
    display flex
    justify-content center
    align-items center
    .rawMateriaPrizeChild
        width 90%
        background #ffffff
        .engineering-head
            width 100%
            background #0C2C87
            text-align center
            color #fff
            padding .1rem 0;
            display flex
            justify-content space-between
            align-items center
            span
                font-size .2rem
                &:last-child
                    margin-right .2rem
                    cursor pointer
    .engineering-content
        display flex
        padding .2rem
        .child-one
            flex 1
            .table-title
                width 100%
                text-align center
                height .4rem
                font-size .18rem
                color #333333
                background #EDF0F5
                line-height .4rem
                border 1px solid #D7D7D7
            .el-table__body-wrapper
                tr td:last-child
                    color #606266
                    cursor auto
            .el-table
                thead tr th
                    background #EDF0F5
                    color #022782
                    font-weight 800
                td, th
                    padding 0
                    text-align center
                    font-size .14rem
            .el-table__footer tr td
                background none
                color #C9661E
                &:first-child
                    background #F5F2ED
                    color #823802
        .child-two
            flex 1
            margin-left .2rem
            border 1px solid #D7D7D7
            padding .2rem
            h1
                color #333333
                font-size .18rem
                font-weight 800
                &:before
                    content '`'
                    width .06rem
                    height .2rem
                    background #4D7DE0
                    color #4D7DE0
                    margin-right .1rem
</style>